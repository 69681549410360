<template>
  <div class="container">
    <kdx-header-bar>
      <Form ref="form" :model="searchData" :label-width="100" inline @submit.native.prevent>
        <FormItem label="支付状态：" style="margin-right: 20px;">
          <Select v-model="searchData.status" class="width-160">
            <Option v-for="(item, index) in statusOptions" :key="index" :value="item.value">
              {{ item.label }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="活动进行状态：">
          <Select v-model="searchData.isAfoot" class="width-160">
            <Option v-for="(item, index) in isAfootOptions" :key="index" :value="item.value">
              {{ item.label }}
            </Option>
          </Select>
        </FormItem>
        <div class="ivu-form-item-btn">
          <Button type="primary" @click="handleSearch">搜索</Button>
          <Button type="text" @click="handleReset">重置</Button>
        </div>
      </Form>
    </kdx-header-bar>

    <div class="list-wrap" v-loading="loading">
      <div class="custom-table">
        <div class="theader">
          <ul>
            <li class='goods'>商品</li>
            <li class="buyer">买家</li>
            <li class="price">单价</li>
            <li class="price">数量</li>
            <li class="num">实付款</li>
            <li class="status">支付状态</li>
            <li class="active-status">活动进行状态</li>
            <li class="setting">操作</li>
          </ul>
        </div>
        <div class="tbody" v-if="list.length">
          <div class="tr" v-for="(item, index) in list" :key="index">
            <div class="code">
              <div>
                <span class="text">下单时间：</span>
                <span style="margin-right: 20px;">{{ new Date(item.payTime * 1000) | dateFormat }}</span>
                <div class="text">订单编号：</div>
                {{ item.number }}
                <div class="copy" v-clipboard:copy="item.number" v-clipboard:success="onCopySuccess"
                  v-clipboard:error="onCopyError">
                  复制
                </div>
              </div>
              <!-- <span>订单详情</span> -->
            </div>
            <ul>
              <li class="goods-cell">
                <img :src="item.shopInfo.pic" alt="">
                <div class="conetnt-text">
                  <template v-if="item.shopType == 2">
                    <span class="mark real" v-if="item.shopInfo.type == '1'">实</span>
                    <span class="mark virtual" v-if="item.shopInfo.type == '2'">虚</span>
                  </template>
                  <span class="text">{{ item.shopInfo.title }}</span>
                </div>
              </li>
              <li class="buyer-cell">
                <div class="box">
                  {{ item.customerInfo.name }}<br />
                  {{ item.customerInfo.mobile }}
                </div>
              </li>
              <li class="price-cell">
                <div class="box">
                  ￥{{ (item.shopInfo.discou * 1).toFixed(2) }}
                </div>
              </li>
              <li class="price-cell">
                <div class="box">
                  x {{ item.num }}
                </div>
              </li>

              <li class="num-cell">
                <div class="box">
                  ￥{{ (item.money * 1).toFixed(2) }}
                </div>
              </li>
              <li class="status-cell">
                <div class="box" :class="{
                  applying: item.status == '0',
                  paid: item.status == '1',
                  refuse: item.status == '2',
                }">
                  {{ item.statusName }}
                </div>
              </li>
              <li class="active-status-cell">
                <div class="box" :class="item.isAfoot == 1 ? 'end' : 'active'">
                  {{ item.isAfoot == 1 ? '已结束' : '进行中' }}
                </div>
              </li>
              <li class="setting-cell">
                <div class="box">-</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="nodata" v-else>暂无数据</div>
      </div>
      <div class="footer-page">
        <kdx-page-component ref="page" :total="total" @on-change="changePage"></kdx-page-component>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>

export default {
  inject: ['returnToTop'],
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      total: 0,
      page: 1,
      pagesize: 10,
      list: [],
      searchData: {
        status: -1,
        isAfoot: -1,
      },
      statusOptions: [
        { label: "全部", value: -1 },
        { label: "待付款", value: 0 },
        { label: "已付款", value: 1 },
        { label: "订单关闭", value: 2 },
      ],
      isAfootOptions: [
        { label: "全部", value: -1 },
        { label: "已结束", value: 0 },
        { label: "进行中", value: 1 },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 复制成功
    onCopySuccess() {
      this.$Message.success('内容已复制到剪切板！');
    },
    // 复制失败
    onCopyError() {
      this.$Message.error('复制失败！');
    },
    async getData() {
      this.returnToTop();
      this.loading = true;
      const { page, pagesize: size } = this;
      const { status, isAfoot } = this.searchData;
      const result = await this.$api.order.orderlist({ page, size, status, isAfoot });
      this.list = result.list;
      this.total = result.count;
      this.loading = false;
    },
    // 点击搜索按钮
    handleSearch() {
      this.page = 1;
      this.pagesize = 10;
      this.$refs['page'].reset();
      this.getData();
    },
    // 点击重置按钮
    handleReset() {
      this.searchData = {
        status: -1,
        isAfoot: -1,
      };
      this.page = 1;
      this.pagesize = 10;
      this.$refs['page'].reset();
      this.getData();
    },

    // 切换页码
    changePage(page) {
      this.page = page.pageNumber;
      this.pagesize = page.pageSize;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #f4f6f8;
  margin: 20px auto;
}

.list-wrap {
  padding: 0 20px;
  background: #fff;
  position: relative;
}

.ivu-form .ivu-form-item {
  margin-bottom: 0;

  &:nth-child(1) {
    margin-bottom: 20px;
  }
}

.custom-table {
  font-size: 14px;
  line-height: 20px;

  ul {
    display: flex;

    li {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 20px;
      flex-shrink: 0;

      &:nth-child(1) {
        width: 236px;
      }

      &:nth-child(2) {
        flex: 1;
      }

      &:nth-child(3) {
        flex: 1;
      }

      &:nth-child(4) {
        width: 80px;
      }

      &:nth-child(5) {
        flex: 1;
      }

      &:nth-child(6) {
        width: 120px;
      }

      &:nth-child(7) {
        flex: 1;
      }

      &:nth-child(8) {
        flex: 1;
      }

      &:nth-child(9) {
        width: 240px;
      }
    }
  }

  .theader {
    ul {
      height: 64px;

      li {
        height: 64px;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #262b30;
      }
    }
  }

  .tbody {
    .tr {
      border: 1px solid #e9edef;
      border-radius: 2px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .code {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >div {
          display: flex;
          align-items: center;
        }


        height: 50px;
        background-color: #f4f6f8;
        color: #939799;

        .text {
          font-weight: bold;
        }

        >span {
          color: $brand-color;
          padding: 0 10px;
          cursor: pointer;
        }

        .copy {
          color: $brand-color;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      ul {
        padding-top: 20px;
        padding-bottom: 20px;

        .goods-cell {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          img {
            width: 80px;
            height: 80px;
            margin-right: 10px;
            border: 1px solid var(--border-color);
            overflow: hidden;
            border-radius: 2px;
            box-sizing: border-box;
            flex-shrink: 0;
          }

          .conetnt-text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            height: 60px;

            .mark {
              font-size: 12px;
              line-height: 16px;
              color: #ed4014;
              background-color: #ffefe6;
              border-radius: 2px;
              margin-right: 4px;
              width: 18px;
              height: 18px;
              display: inline-block;
              text-align: center;
              line-height: 18px;

              &.real {
                background-color: #F0FAFF;
                color: var(--theme-color);
              }

              &.virtual {
                background-color: #E6FFF9;
                color: #00C5C5;
              }
            }
          }

        }

        .num-cell {
          .box {
            font-weight: bold;
          }
        }



        .status-cell {
          .box {
            &.applying {
              color: #ff9900;
            }

            &.paid {
              color: #19be6b;
            }

            &.payment {
              color: $brand-color;
            }

            &.refuse {
              color: #ff1f2c;
            }
          }
        }

        .active-status-cell {
          .box {
            &.end {
              color: #ff1f2c;
            }

            &.active {
              color: #19be6b;
            }
          }
        }

        li:nth-child(9) {
          .box {
            .mL-10 {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .nodata {
    text-align: center;
    color: #262b30;
    font-size: 14px;
    height: 48px;
    line-height: 48px;
    border-top: 1px solid #e8eaec;
    border-bottom: 1px solid #e8eaec;
  }
}

.footer-page {
  border-top: none;
}
</style>